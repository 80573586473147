<template>
  <div class="page-table mainDiv mb-30" id="affix-container">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.yonetici.guncelleme.title") }}
    </div>

    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="loading || detailLoading"
         :element-loading-text='loading ? $t("src.views.apps.yonetici.guncelleme.updateLoading") : $t("src.views.apps.yonetici.guncelleme.stateLoading")'
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="yoneticiForm" :rules="rulesYonetici" ref="yoneticiForm" class="demo-ruleForm">
        <div class="card-base card-shadow--medium animated fadeInUp">
          <el-row type="flex" justify="center">
            <el-col :lg="4" :md="4" :sm="20" :xs="20" class="col-p"
                    style="display: flex !important; justify-content: center !important; padding-top: 15px !important">
              <el-form-item prop="image">
                <el-upload
                    action=""
                    :auto-upload="false"
                    :limit="1"
                    id="yoneticiguncelle-resim"
                    :file-list="imageListMain"
                    :on-change="imageUpload"
                    :on-remove="imageRemove"
                    accept=".jpg,.jpeg,.png"
                    ref="upload"
                    list-type="picture-card">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" justify="center">
            <el-col :lg="10" :md="10" :sm="24" :xs="24" class="col-p">


              <el-col :lg="16" :md="16" :sm="16" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.kullaniciAdi")'
                    prop="kullaniciAdi">: &nbsp;
                  {{ yoneticiForm.kullaniciAdi }}
                </el-form-item>
              </el-col>

              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.yetki")' prop="tur">:
                  <el-select
                      size="small"
                      v-model="yoneticiForm.tur"
                      style="width: 100%">
                    <el-option value="1" label="Yönetici"></el-option>
                    <el-option value="2" label="Birim Yöneticisi"></el-option>
                    <el-option value="3" label="Personel"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p"
                      v-if="yoneticiForm.tur == 2 || yoneticiForm.tur == 3">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.BG")'
                    prop="sozlesmeKategoriID">:
                  <el-select
                      size="small"
                      v-model="yoneticiForm.sozlesmeKategoriID"
                      style="width: 100%">
                    <el-option value="1" label="BG Proje"></el-option>
                    <el-option value="2" label="BG İnşaat"></el-option>
                    <el-option value="3" label="BG Prefabrik"></el-option>
                    <el-option value="4" label="BG Çelik"></el-option>
                    <el-option value="5" label="BG Pamel"></el-option>
                    <el-option value="6" label="BG Lojistik"></el-option>
                    <el-option value="7" label="BG Enerji"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.adSoyad")' prop="adSoyad">:
                  <el-input v-model="yoneticiForm.adSoyad"
                            :placeholder='$t("src.views.apps.messages.enterNameSurname")'></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.email")' prop="eposta">:
                  <el-input v-model="yoneticiForm.eposta"
                            :placeholder='$t("src.views.apps.messages.enterMail")'></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.cepTelefon")' prop="cepTelefon">:
                  <el-input v-model="yoneticiForm.cepTelefon" v-mask="'+90(###)### ## ##'"
                            placeholder="+90(555)555 55 55"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.telefonSabit")' prop="sabitTelefon">:
                  <el-input v-model="yoneticiForm.sabitTelefon" v-mask="'+90(###)### ## ##'"
                            placeholder="+90(346)555 55 55"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.dahili")' prop="dahili">:
                  <el-input v-model="yoneticiForm.dahili" v-mask="'######'" placeholder="555555"></el-input>
                </el-form-item>
              </el-col>

              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <div class="display-end mb-10">
                  <el-form-item>
                    <el-popover placement="top" width="300" v-model="visibleSil">
                      <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="primary" @click="resetForm('yoneticiForm')">
                          {{ $t('src.views.apps.genel.yes') }}
                        </el-button>
                        <el-button size="mini" @click="visibleSil = false;">
                          {{ $t('src.views.apps.genel.no') }}
                        </el-button>
                      </div>
                      <el-button size="small" slot="reference">
                        {{ $t('src.views.apps.genel.temizle') }}
                      </el-button>
                    </el-popover>
                    &nbsp;
                    <el-button v-on:click="yoneticiGuncelle('yoneticiForm')" size="small" type="primary">
                      {{ $t("src.views.apps.genel.guncelle") }}
                    </el-button>

                  </el-form-item>
                </div>
              </el-col>

            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import yoneticiService from '../../../WSProvider/YoneticiService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from "@/components/event-bus";
import JQuery from 'jquery';

let $ = JQuery;

export default {
  name: "PersonelDuzenle",
  components: {Password},
  mounted() {
    this.yonetimUpdateData = this.$store.getters.getYoneticiUpdateData;

    this.getYoneticiDetay();
  },
  computed: {
    yoneticiUpdateData() {
      return this.$store.getters.getYoneticiUpdateData;
    }
  },
  watch: {
    yoneticiUpdateData(val) {
      this.yonetimUpdateData = val
      this.getYoneticiDetay();
    }
  },
  data() {
    return {
      loading: false,
      detailLoading: false,

      imageListMain: [],
      visibleSil: false,
      yonetimUpdateData: '',
      yoneticiForm: {
        yoneticiID: "",
        sozlesmeKategoriID: "",
        sozlesmeKategoriAdi: "",
        image: [],
        tur: '2',
        tcNo: '',
        yetki: '2',
        kullaniciAdi: '',
        adSoyad: '',
        eposta: '',
        cepTelefon: '',
        sabitTelefon: '',
        dahili: '',
      },
      rulesYonetici: {
        eposta: [{
          required: true,
          message: this.$t('src.views.apps.messages.enterMail'),
          trigger: ["blur", "change"]
        }, {type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: ['blur', 'change']}],
      },
    }
  },
  methods: {
    refreshPage() {
      this.loading = false;
      this.detailLoading = false;
      this.getYoneticiDetay();
    },

    imageRemove() {
      $('#yoneticiguncelle-resim .el-upload--picture-card').show(); // birden fazla resim eklenmesini istemiyoruz !
      this.imageListMain = [];
      this.yoneticiForm.image = [];
    },

    imageUpload(file) {
      this.imageListMain = []
      this.yoneticiForm.image = []
      if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
        var item = {
          name: file.name,
          url: file.url,
          id: file.raw.lastModified,
          size: file.raw.size
        };
        this.imageListMain.push(item)

        file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor

        this.yoneticiForm.image.push(file.raw)
        $('#yoneticiguncelle-resim .el-upload--picture-card').hide();
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      $('.el-upload-list__item-delete').click();
      this.visibleSil = false;
      functions.sayfaKapat("personelguncelle", this);
      functions.routeSayfa("Personel Listesi", this);

    },

    getYoneticiDetay() {
      this.imageRemove() // sayfa acık kaldıgında baska bir yoneticiyi guncellemek için butona tıkladıgında aynı sayfada acılıyor. O zaman resimler üst üste ekleniyor. Bu hatayı engellemek için!
      if (this.yonetimUpdateData.resim) {
        if (this.yonetimUpdateData.resim !== '') {
          this.imageListMain.push({
            name: this.yonetimUpdateData.resim,
            url: yoneticiService.imagePath + this.yonetimUpdateData.resim
          });
        }
        $('#yoneticiguncelle-resim .el-upload--picture-card').hide();
        this.yoneticiForm.image.push(this.yonetimUpdateData.resim)
      }
      //console.log(this.yonetimUpdateData)
      this.loading = false;
      this.detailLoading = true;
      this.yoneticiForm.yoneticiID = this.yonetimUpdateData.ID;
      this.yoneticiForm.yetki = this.yonetimUpdateData.yetkiTuru;
      this.yoneticiForm.cepTelefon = this.yonetimUpdateData.cepTel;
      this.yoneticiForm.sabitTelefon = this.yonetimUpdateData.sabitTel;
      this.yoneticiForm.dahili = this.yonetimUpdateData.dahiliTel;
      this.yoneticiForm.adSoyad = this.yonetimUpdateData.adSoyad;
      this.yoneticiForm.eposta = this.yonetimUpdateData.eposta;
      this.yoneticiForm.tur = this.yonetimUpdateData.rol;
      this.yoneticiForm.sozlesmeKategoriID = this.yonetimUpdateData.sozlesmeKategoriID;
      this.yoneticiForm.kullaniciAdi = this.yonetimUpdateData.yoneticiAdi;
      this.detailLoading = false;
    },
    yoneticiGuncelle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Yetki türünü değiştirmek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            try {
              this.loading = true;
              yoneticiService.yoneticiGuncelle(this.yoneticiForm.yoneticiID, this.yoneticiForm.sozlesmeKategoriID, this.yoneticiForm.image.length > 0 ? this.yoneticiForm.image[0] : '', this.yoneticiForm.adSoyad, this.yoneticiForm.eposta, this.yoneticiForm.cepTelefon, this.yoneticiForm.sabitTelefon, this.yoneticiForm.dahili, this.yoneticiForm.tur, this.yoneticiForm.eposta).then(response => {
                if (response.status == 200) {
                  notification.Status('success', this, this.$t('src.views.apps.messages.adminUpdateSuccess'));
                  this.resetForm(formName);
                  localStorage.setItem("userDataBGSurec", response.token)
                  EventBus.$emit("personelList", true)
                  functions.sayfaKapat("personelguncelle", this)
                  functions.routeSayfa("Personel Listesi", this);
                }
                this.loading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.loading = false;
              })
            } catch (e) {
              // console.log(e);
              notification.Status("danger", this, this.$t('src.views.apps.messages.adminUpdateError'))
            }
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },


  }
}

</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.aciklamaSpan {
  position: absolute;
  top: 25px;
}

.page-table {
  &.overflow {
    overflow: hidden;
  }

  .table-box {
    overflow: hidden;
  }
}

.el-form-item {
  font-weight: bold;
}
</style>

<style lang="scss">
#yoneticiguncelle-resim {
  .el-upload-list__item {
    transition: none !important;
  }
}
</style>